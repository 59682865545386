@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-slideshow-image/dist/styles.css";

@font-face {
  font-family: "Drawings";
  src:
    url("./Fonts/Drawings.woff") format("woff"),
    url("./Fonts/Drawings.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Flowers";
  src:
    url("./Fonts/Flowers.woff") format("woff"),
    url("./Fonts/Flowers.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dash";
  src: url("./Fonts/Dash.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.box:after {
  content: "";
  height: 0.5em;
  width: 100%;
  position: absolute;
  bottom: -0.5em;
  left: 0;
  background: -webkit-linear-gradient(
    0deg,
    rgba(232, 222, 171, 1) 0%,
    rgba(203, 180, 128, 1) 100%
  );
  background: -moz-linear-gradient(
    0deg,
    rgba(232, 222, 171, 1) 0%,
    rgba(203, 180, 128, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(232, 222, 171, 1) 0%,
    rgba(203, 180, 128, 1) 100%
  );
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*[data-ico-flowers]:before {
  font-family: Flowers;
  content: attr(data-ico-flowers);
  transition: all 280ms ease;
}

*[data-ico-drawings]:before {
  font-family: Drawings;
  content: attr(data-ico-drawings);
  transition: all 280ms ease;
}

.superheadline:before {
  content: "\e901";
  font-family: Dash;
  font-size: 0.6rem;
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 1em;
  padding-top: 3px;
}

.superheadline:after {
  content: "\e900";
  font-family: Dash;
  font-size: 0.6rem;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 1em;
  padding-top: 3px;
}

.viewmore:before,
.viewmore:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: 0;
  background: #749b5d;
}

.viewmore:before {
  left: 0;
}

.viewmore:after {
  right: 0;
  background: #8c857a;
  transition: width 400ms cubic-bezier(.22,.61,.36,1);
}

/* .group:hover .viewmore:before, */
.viewmore:hover:before {
  left: 0;
  background: #749b5d;
  width: 100%;
  bottom: 0;
  transition: width 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

/* .group:hover .viewmore:after, */
.viewmore:hover:after {
  background: transparent;
  width: 100%;
  bottom: 0;
  transition: 0s;
}
