.fade {
  background: linear-gradient(90deg,#132038,#171e2e);
  position: relative;
}

.fade:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 2.5rem 2.5rem;
  border-color: transparent transparent #fff transparent;
}

.fade:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 2.5rem 2.5rem;
  border-color: transparent transparent transparent #fff;
}

@media all and (max-width: 467px) {
  .fade:after {
    border-width: 0 0 1.5rem 1.5rem;
  }
  .fade:before {
    border-width: 0 0 1.5rem 1.5rem;
  }
} 

:root {
  --percentage: 0%;
}

h1 {
  background-image: linear-gradient(75deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 33.33%, rgba(23, 30, 46, 0) 66.67%, rgba(23, 30, 46, 0) 100%);
  background-size: 300% 100%;
  background-position-x: calc(100% - var(--percentage));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}